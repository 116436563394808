import ApplicationController from '../application_controller';
import { showLoadingSpinner } from '../utilities/loading_spinner';

export default class extends ApplicationController {
  static targets = ['metforminForm', 'accordion'];

  connect() {
    super.connect();

    this.submitMetforminForm();
    this.initializeAccordion();
  }

  disconnect() {
    super.disconnect();
  }

  submitMetforminForm() {
    if (this.hasMetforminFormTarget) {
      showLoadingSpinner();
      this.metforminFormTarget.submit();
    }
  }

  initializeAccordion() {
    if (this.hasAccordionTarget) {
      const $el = $(this.accordionTarget);
      $el.accordion({
        active: false,
        collapsible: true,
        heightStyle: 'content',
        beforeActivate: function(_event, ui) {
          const oldHeader = ui.oldHeader[0];
          const newHeader = ui.newHeader[0];
          if (oldHeader) oldHeader.querySelector('img').classList.add('rotate-180');
          if (newHeader) newHeader.querySelector('img').classList.remove('rotate-180');
        }
      });
    }
  }

  enableFormSubmit(event) {
    const submit = event.currentTarget.closest('form').querySelector('input[type=submit]');
    if (submit) {
      submit.removeAttribute('disabled')
      submit.classList.remove('store-button-next-disabled');
      submit.classList.add('store-button-next-active');
    }
  }

  disableFormSubmit(event) {
    const submit = event.currentTarget.closest('form').querySelector('input[type=submit]');
    if (submit) {
      submit.setAttribute('disabled', 'disabled')
      submit.classList.remove('store-button-next-active');
      submit.classList.add('store-button-next-disabled');
    }
  }

  consentToggled(event) {
    event.currentTarget.checked ? this.enableFormSubmit(event) : this.disableFormSubmit(event);
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::OrderSummary#toggle_consent_accepted',
          event.currentTarget.checked,
        );
      },
    });
  }

  showLoadingSpinner() {
    showLoadingSpinner();
  }
}
