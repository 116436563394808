import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  toggle(event) {
    event.preventDefault();
    const content = event.currentTarget.closest('.flex').nextElementSibling;
    const openIcon = event.currentTarget
      .closest('div')
      .querySelector('.open-icon');
    const closeIcon = event.currentTarget
      .closest('div')
      .querySelector('.close-icon');

    if (content.classList.contains('max-h-0')) {
      content.classList.remove('max-h-0');
      content.classList.add('max-h-screen');
      openIcon.classList.add('hidden');
      closeIcon.classList.remove('hidden');
    } else {
      content.classList.remove('max-h-screen');
      content.classList.add('max-h-0');
      openIcon.classList.remove('hidden');
      closeIcon.classList.add('hidden');
    }
  }
}
