import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ["yesInput", "noInput", "commentArea", "commentContainer"]

  connect() {
    super.connect();
  }

  toggle() {
    if (this.yesInputTarget.checked) {
      this.commentContainerTarget.classList.remove('hidden')
      this.commentContainerTarget.classList.add('flex')
      this.commentAreaTarget.disabled = false
      this.commentAreaTarget.required = true
    } else {
      this.commentContainerTarget.classList.add('hidden')
      this.commentContainerTarget.classList.remove('flex')
      this.commentAreaTarget.disabled = true
      this.commentAreaTarget.required = false
    }
  }
}
