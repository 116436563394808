import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = [
    "orderId",
    "medicationCategoryId",
    "productId",
    "variantId",
    "isEscalating",
  ];

  connect() {
    super.connect();
  }

  updateMedicationCategory(event) {
    const medicationCategoryId = event.target.value;
    this.medicationCategoryIdTarget.value = medicationCategoryId;
    this.updatePrescriptionForm();
  }

  updatePrescriptionForm(event) {
    event?.preventDefault();
    event?.stopPropagation();

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          "Cp::Doctor::TreatmentPlan#update_prescription_form",
          this.medicationCategoryIdTarget.value,
          this.orderIdTarget.value,
          this.productIdTarget.value,
          this.variantIdTarget.value,
          this.isEscalatingTarget.value
        );
      },
    });
  }
}
